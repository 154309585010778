.rct9k-timeline-div {
  width: 100%;
  height: 95vh;
  background-color: #a5a5a5;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Timeline items */
.rct9k-items-outer {
  white-space: nowrap;
  position: absolute;
  display: inline-block;
  overflow: hidden;
  z-index: 3;
}
.rct9k-items-inner {
  display: inline-block;
  margin: 3px;
  padding: 3px;
  width: 100%;
}
.rct9k-marker-overlay {
  z-index: 2;
  height: 475px;
  position: absolute;
  width: 2px;
  background: red;
  pointer-events: none;
}
.rct9k-item-renderer-inner {
  pointer-events: none;
}
.rct9k-row {
  /* white-space: nowrap; */
  box-sizing: border-box;
  border: 1px solid black;
  border-top: 0;
}
.rct9k-row:nth-child(2) {
  border-top: 1px solid black;
}

.rct9k-items-selected {
  background-color: magenta;
}

.ReactVirtualized__Grid {
  overflow-x: hidden;
}

/* Timeline groups */
.rct9k-group {
  text-align: center;
  box-sizing: border-box;
  border: 1px solid black;
  border-top: 0;
  background-color: white;
  z-index: 6;
}

.rct9k-group:first-child {
  border-top: 1px solid black;
}

/* Timeline top timebar */
.rct9k-timebar-outer {
  background-color: white;
  border: 1px solid black;
  box-sizing: border-box;
}
.rct9k-timebar-inner {
  white-space: nowrap;
  overflow: hidden;
}
.rct9k-timebar-item {
  text-align: center;
  display: inline-block;
  border-left: 1px solid;
  white-space: nowrap;
  box-sizing: border-box;
}
.rct9k-timebar-item-selected {
  background-color: lightblue;
}

.rct9k-timebar-group-title {
  display: inline-block;
  position: absolute;
  height: 4em;
}

/* Multi-select box */
.rct9k-selector-outer {
  display: inline-block;
  background-color: #5bb3ff80;
  width: 1px;
  height: 1px;
  position: fixed;
  z-index: 5;
}

/* Row layer */
.rct9k-row-layer {
  pointer-events: none;
  position: absolute;
  z-index: 1;
}